import React from "react"
import Image from "next/image"
import { useRouter } from "next/router"
import { useTranslation } from "next-i18next"
import { useMediaQuery } from "react-responsive"
import tw from "twin.macro"

import { When } from "@components/If"
import Container from "@components/v2/Container"
import { Button } from "@components/v3/Button"
import useAuth from "@hooks/useAuth"
import Space from "@layouts/Space"

import MockFooter from "../images/mock-footer.webp"
import MockFooterMobile from "../images/mock-footer-mobile.webp"

const StyledContainer = tw(Container)`
    xl:py-10 py-8
`

const Wrapper = tw.div`
    rounded-3xl
    xl:py-0
    pl-0 pr-0 xl:pr-16
    flex xl:flex-row flex-col
    xl:gap-10
    xl:bg-gradient-to-r bg-gradient-to-b from-[#00B7BD] to-[#85E0E3]
    xl:dark:bg-gradient-to-r dark:bg-gradient-to-b dark:from-[#207E81] dark:to-[#47D2D7]
`

const Title = tw.h1`
    xl:heading-2 heading-5
    text-bold 
    xl:text-left text-center
`

const Desc = tw.span`
    xl:paragraph-2 paragraph-3 
    text-main 
    xl:text-left text-center
    xl:max-w-[588px]
`

const CTA = () => {
    const { t } = useTranslation("home")
    const router = useRouter()
    const {
        auth: { isLoggedIn }
    } = useAuth()
    const isDesktop = useMediaQuery({ minWidth: 1280 })
    return (
        <StyledContainer>
            <Wrapper>
                <div className='w-full'>
                    <Image
                        src={isDesktop ? MockFooter.src : MockFooterMobile.src}
                        width={588}
                        height={360}
                        layout='responsive'
                        className='xl:rounded-bl-3xl'
                        alt='mock-cta'
                    />
                </div>
                <Space
                    direction='vertical'
                    justify='center'
                    className='xl:gap-8 !gap-4 !w-full xl:!px-0 !px-4 xl:py-0 py-8'
                >
                    <Space direction='vertical' className="xl:!gap-4'">
                        <Title>{t("us_stock.CTA.title")}</Title>
                        <Desc>{t("us_stock.CTA.desc")}</Desc>
                    </Space>
                    <When condition={!isLoggedIn}>
                        <Button
                            block={!isDesktop}
                            variant='secondary'
                            size={isDesktop ? "xl" : "lg"}
                            width={isDesktop ? 156 : undefined}
                            onClick={() => router.push("/uss/register")}
                        >
                            {t("common:sign_up")}
                        </Button>
                    </When>
                </Space>
            </Wrapper>
        </StyledContainer>
    )
}

export default CTA
